import React from "react";
import firebase from "./firebase";
import ReactMarkdown from "react-markdown";

import Navbar from './Navbar';

export default class NewsletterEdit extends React.Component {
  constructor(props) {
    super(props);
    this.db = firebase.firestore();

    this.state = { loading: true };
    this.db.collection("global").doc("admins").get().then((doc) => {
      if (doc.exists) {
        if (doc.data().admins.includes(this.props.uid)) {
          this.setState({ loading: false, text: "", title: "", author: "", description: "", updateFeature: true });
        } else {
          window.location.href = "/error/admin";
        }
      }
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="absolute top-0 bottom-0 right-0 left-0 flex justify-center items-center h-screen w-screen">
          <div className="loader"></div>
        </div>
      )
    }

    return (
      <div>
        <Navbar />
        <div className="py-10 border-b-2 border-gray-100">
          <div className="max-w-3xl mx-auto px-4 md:px-0">
            <p className="text-5xl text-gray-900 font-semibold">
              Publish Newsletter
                        </p>
            <p className="text-xl text-gray-500">
              Extra! Extra! What's the latest happenings?
                        </p>
          </div>
        </div>
        <div className="max-w-3xl mx-auto mt-8 px-4 md:px-0 -my-4 pb-20">
          <input
            onChange={(event) => {
              this.setState({ title: event.target.value });
            }}
            value={this.state.title}
            className={'mb-4 text-xl appearance-none border border-gray-100 w-full p-5 text-gray-700 focus:outline-none focus:bg-white focus:border-blue-500 ' + (this.state.title == "" ? "border-red-300" : "bg-gray-100")}
            placeholder='Title your newsletter...'
            name="title"
            required
          />
          <input
            onChange={(event) => {
              this.setState({ description: event.target.value });
            }}
            value={this.state.description}
            className={'mb-4 text-xl appearance-none border border-gray-100 w-full p-5 text-gray-700 focus:outline-none focus:bg-white  focus:border-blue-500 ' + (this.state.description == "" ? "border-red-300" : "bg-gray-100")}
            placeholder='A short, witty description to attract readers.'
            name="description"
            required
          />
          <input
            onChange={(event) => {
              this.setState({ author: event.target.value });
            }}
            value={this.state.author}
            className={'mb-4 text-xl appearance-none border border-gray-100 w-full p-5 text-gray-700 focus:outline-none focus:bg-white  focus:border-blue-500 ' + (this.state.author == "" ? "border-red-300" : "bg-gray-100")}
            placeholder='Author name'
            name="author"
            required
          />
          <p className="text-gray-500">
            You can use <a href="https://www.markdownguide.org/cheat-sheet/" target="_blank" className="text-indigo-600 underline">markdown</a> in your story!<br />
            Basic formatting: <b>**bold**</b> <i>*italics*</i> <code>&gt; quote</code> <code>`code`</code>
          </p>
          <textarea
            onChange={(event) => {
              this.setState({ text: event.target.value.split("\n\n\n").join("\n\n&nbsp;\n\n") });
            }}
            value={this.state.text}
            className="mb-4 text-xl bg-gray-100 appearance-none border border-gray-100 w-full p-5 text-gray-700 focus:outline-none  focus:bg-white focus:border-blue-500"
            placeholder="Once upon a time..."
            name="textentry"
            rows="25"
          />
          <div className="max-w-3xl shadow-2xl mx-auto p-10 mt-5">
            <p className="font-semibold text-black text-2xl leading-tight">Preview:</p>
            <ReactMarkdown source={this.state.text} className="markdown preview" />
          </div>
          <div className="max-w-3xl shadow-2xl mx-auto p-4 mt-8">
            <p className="font-semibold text-black text-2xl leading-tight mb-2 mt-8 ml-8">Ready to publish?</p>
            <div className="mb-2 ml-8 mt-4">
              <input
                className="mr-2 leading-tight"
                onChange={(event) => {
                  this.setState({ updateFeature: event.target.checked });
                }}
                type="checkbox"
                checked={this.state.updateFeature}
                required
              />
              <span className="text-md">Feature story on homepage</span>
            </div>
            <button className="text-xl mt-6 mb-4 ml-8 px-10 py-5 bg-white text-black border-2 border-gray-200 hover:border-black font-semibold border-transition-7" onClick={() => {
              if (this.state.text == "" || this.state.title == "" || this.state.author == "" || this.state.description == "" || this.state.loading) return;
              this.setState({ loading: true });
              this.db.collection("newsletters").add({
                featured: this.state.updateFeature,
                author: this.state.author,
                description: this.state.description,
                timestamp: new Date(),
                title: this.state.title,
                body: this.state.text,
                creator: {
                  uid: this.props.uid
                },
                deleted: false
              }).then((doc) => {
                window.location.href = "/newsletter/" + doc.id;
              })
            }}>
              {
                this.state.loading ? "Loading..." : "Publish Story"
              }
              <i className="fas fa-angle-right ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}