import React, { Component } from 'react';
import Idea from './Idea';
import SortButton from './SortButton';
import firebase from "./firebase";
import Navbar from './Navbar';
import DiscoverIdea from './DiscoverIdea';

class IdeaList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ideas: null,
      dataPulled: false,
      sort: "1",
      load: 5,
      loadingMore: false,
      polls: null
    }
    this.db = firebase.firestore();

  }

  loadMoreIdeas = () => {
    if (!this.state.loadingMore) {
      this.setState({
        load: this.state.load += 5,
        loadingMore: true
      });
      this.getIdeas();
    }
  }

  changeSort = (newSort) => {
    this.setState({
      sort: newSort,
      ideas: null,
      dataPulled: false
    }, () => {
      this.getIdeas();
    });
  }

  ideasPulled = (ideas) => {
    this.setState({
      ideas: ideas,
      dataPulled: true,
      loadingMore: false
    });
  }

  getIdeas = () => {
    const db = this.db;
    const uid = this.props.uid;

    let ideas = [];
    let ideasRef = db.collection("ideas").where("deleted", "==", false);
    if (this.props.type != "admin") {
      ideasRef = ideasRef.where("flagged", "==", false);
    }
    if (this.props.type == "topic") {
      ideasRef = ideasRef.where("topic.id", "==", this.props.tid);
    } else if (this.props.type == "user") {
      ideasRef = ideasRef.where("creator.uid", "==", this.props.creatorUid)
    } else if (this.props.type == "admin") {
      ideasRef = ideasRef.where("flagged", "==", true)
    } else {
      ideasRef.where("flagged", "==", false);
    }

    switch (this.state.sort) {
      case "1":
        ideasRef = ideasRef.orderBy("timestamp", "desc");
        break;
      case "2":
        ideasRef = ideasRef.orderBy("timestamp", "asc");
        break;
      case "3":
        ideasRef = ideasRef.orderBy("likes", "desc");
        break;
      case "4":
        ideasRef = ideasRef.orderBy("likes", "asc")
        break;
    }

    ideasRef.get().then((querySnapshot) => {
      this.setState({
        polls: querySnapshot.size
      });
    }, (e) => {
      console.error(e);
    });

    ideasRef.limit(this.state.load).get().then((querySnapshot) => {
      if (querySnapshot.size > 0) {
        let i1 = 0;
        querySnapshot.forEach((doc) => {
          i1++;

          let idea = doc.data();

          idea.id = doc.id;
          idea.timestamp = idea.timestamp.toDate();
          idea.liked = idea.likers.includes(uid);

          ideas.push(idea);

          if (i1 == querySnapshot.size) {
            ideas.forEach((idea, i2) => {
              db.collection("topics").doc(idea.topic.id).get().then((docTopic) => {
                if (docTopic.exists) {
                  const topic = docTopic.data();
                  idea.topic.name = topic.name;
                }
              }).then(() => {
                if (i2 == ideas.length - 1) {
                  this.ideasPulled(ideas);
                }
              }, (e) => {
                console.error(e);
              });
            });
          }
        });
      } else {
        this.ideasPulled(ideas);
      }
    }, (e) => {
      console.error(e);
    });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);

    this.getIdeas();
  }

  handleScroll = () => {
    const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight) {
      this.loadMoreIdeas();
    }
  }


  render() {
    const empty = this.state.polls == 0;
    const emptyText = "No ideas found.";

    const sort = this.state.sort;

    return (
      <div className="pb-10">

        {this.props.type == "topic" || this.props.type == "home" ?
          <div class="">
            <a href={"/idea/new" + (this.props.type == "topic" ? ("?tid=" + this.props.tid) : "")}>
              <div className="flex-1 w-full mb-5 shadow-xl font-semibold text-lg py-3 px-8 bg-indigo-600 text-center hover:bg-indigo-700 bg-transition text-white rounded-lg">
                <i class="mr-1 fas fa-plus"></i> Post a new idea
            </div>
            </a>
          </div>
          : null}

        <div className="p-2 bg-white flex justify-center mb-2 border items-center">
          <SortButton text="For You" sort="5" fa="lightbulb" selected={sort == 5} changeSort={this.changeSort} indigo={true} />
          <SortButton text="New" sort="1" fa="clock" selected={sort == 1} changeSort={this.changeSort} />
          <SortButton text="Old" sort="2" fa="hourglass-half" selected={sort == 2} changeSort={this.changeSort} />
          <SortButton text="Top" sort="3" fa="trophy" selected={sort == 3} changeSort={this.changeSort} />
          <SortButton text="Quiet" sort="4" fa="stream" selected={sort == 4} changeSort={this.changeSort} />
        </div>

        <div className={"pt-2 min-h-screen " + (!empty ? "-my-6" : "")}>
          {empty ?
            <div className="bg-gray-100 p-5 text-gray-500 text-lg text-center">{emptyText}</div>
            :
            this.state.ideas == null ? (
              <div className="min-h-screen min-w-full">
                <div className="py-10 mx-auto">
                  <div className="loader"></div>
                </div>
              </div>
            )
              :
              sort == 5 ? (
                <div>
                  {
                    Array(this.state.load).fill((
                      <div className="my-5">
                        <DiscoverIdea db={this.db} uid={this.props.uid} displayedIdeas={[]} normalStyling={true} />
                      </div>
                    ))
                  }
                </div>
              ) :
              (
                <div>
                  {
                    this.props.type == "home" ?
                      (
                        <div className="my-5" key="discovery-idea">
                          <DiscoverIdea db={this.db} uid={this.props.uid} displayedIdeas={this.state.ideas.map(i => i.id)} />
                        </div>
                      ) : null
                  }
                  {
                    this.state.ideas.map((idea) => {
                      const deleteBtn = (this.props.uid == idea.creator.uid ? "show" : "hide");
                      return (
                        <div className="my-5" key={idea.id}>
                          <Idea db={this.db} idea={idea} uid={this.props.uid} showDeleteBtn={deleteBtn} showTopicBtn={this.props.type != "topic"} admin={this.props.type == "admin"} />
                        </div>
                      )
                    })
                  }
                </div>
              )
          }
        </div>


        {
          this.state.polls < this.state.load ? "" : (
            <div class="top-0 absolute loader">
            </div>
          )
        }

      </div>
    );
  }
}

export default IdeaList;
