import React, { Component } from 'react';
import { getPopularTopics } from "../helpers";
import firebase from "./firebase"

class ChooseTopic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      topic: '',
      searchText: '',
      dataPulled: true,
      topics: [],
      suggestedTopics: [],
    }

    this.getUrlTopic();
    this.getSuggestedTopics();
  }

  getSuggestedTopics = async () => {
    const suggestedTopics = await getPopularTopics(3);
    this.setState({ suggestedTopics });
  }

  getUrlTopic = () => {
    const urlTid = this.props.urlTid;

    if (urlTid === undefined || urlTid == "") {
      return;
    }

    const db = firebase.firestore();

    db.collection("topics").doc(urlTid).get().then((doc) => {
      if (doc.exists) {
        let topic = doc.data();
        topic.id = doc.id;
        this.setTopic(topic)
      }
    })
  }

  setTopic = (topic) => {
    this.setState({
      topic: topic,
      searchText: '',
      topics: []
    });

    this.props.setTopic(topic);
  }

  searchTopics = (searchText) => {
    const db = firebase.firestore();

    db.collection("topics").where("approved", "==", true).where("searchTerms", "array-contains", searchText).get().then((querySnapshot) => {
      let topics = [];
      querySnapshot.forEach((doc) => {
        const topic = doc.data();

        const topicSearchObj = {
          name: topic.name,
          id: doc.id
        }
        topics.push(topicSearchObj);
      });

      this.setState({
        topics: topics,
        dataPulled: true
      });
    });
  }

  deleteSelection = () => {
    this.setState({
      topic: null
    });

    this.props.setTopic(null);
  }

  handleChange = (event) => {
    const value = event.target.value;

    this.setState({
      searchText: value,
      dataPulled: false
    });
    this.searchTopics(value.toLowerCase());
  }

  render() {
    return (
      <div>
        <div className="">
          <div className="items-center w-full flex justify-between items-center font-semibold text-gray-700">
            <div className="flex items-center">
              <p className="text-gray-800 text-lg font-semibold leading-loose">Choose Topic <span class=" font-light text-base">(optional)</span></p>
            </div>
          </div>
        </div>

        <div className=" max-w-sm">
          <div className="">
            {!this.props.topicSet ?
              <input
                className={'appearance-none border border-gray-100 w-full py-2 px-3 text-gray-600 outline-none bg-gray-100 focus:bg-white focus:border-blue-500 ' + (this.props.topicInvalid ? "border-red-300" : "bg-gray-200")}
                onChange={this.handleChange}
                value={this.state.searchText}
                // placeholder="No topic selected"
              />
              :
              <button onClick={this.deleteSelection} class="cursor-pointer bg-indigo-700 text-white hover:bg-indigo-500 bg-transition py-3 w-full px-3 flex font-semibold items-center" type="button">
                <p class="flex-1 flex justify-start">{this.state.topic.name}</p>
                <i class="fas fa-times ml-1"></i>
              </button>
            }
          </div>
          <div className="bg-gray-100">
            {!(this.state.dataPulled && this.state.topics.length == 0 && this.state.searchText != "") ? "" :
              <div className="">
                <p className="bg-white shadow flex py-3 px-3 text-gray-500 \">
                  No topics found.
                  </p>
              </div>
            }

            {this.state.topics.map((topic) => {
              return <div className="">
                <button type="button" className="bg-gray-100 hover:bg-gray-100 flex w-full font-semibold py-3 px-3 text-gray-700 bg-transition -my-px rounded-lg" onClick={() => {
                  this.setTopic(topic);
                }}>
                  {topic.name}
                </button>
              </div>
            })}
          </div>
        </div>

        <div className="mt-3">
          <p className="text-sm mr-2 py-px font-bold text-gray-500 inline w-auto uppercase">Suggested</p>
          {this.state.searchText.trim() !== "" || this.props.topicSet ? null : this.state.suggestedTopics.map((topic) => {
            return (
              <button type="button" className="text-sm mx-1 px-3 py-px rounded-full font-bold text-indigo-600 bg-gray-200 hover:bg-gray-300 bg-transition" onClick={() => {
                this.setTopic(topic);
              }}>
                {topic.name}
              </button>
            )
          })}
        </div>


      </div >
    )
  }
}

export default ChooseTopic;