import React, { Component } from 'react';
import firebase, {analytics} from "./firebase";
import IdeaList from './IdeaList';
import Navbar from './Navbar';

class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      admin: false
    };
  }

  componentWillMount() {
    const db = firebase.firestore();
    db.collection("global").doc("admins").get().then((doc) => {
      if (doc.exists) {
        if (doc.data().admins.includes(this.props.uid)) {
          this.setState({ admin: true });
        } else {
          window.location.href = "/error/admin";
        }
      }
    });
  }

  render() {
    const admin = this.state.admin;

    return (

      <div class="pb-3">
        {!admin ? (<div className="absolute top-0 bottom-0 right-0 left-0 flex justify-center items-center h-screen w-screen">
          <div className="loader"></div>
        </div>)
          :
          (
            <div>
              <Navbar />

              <div className="py-20 bg-gray-800">
                <div className="max-w-3xl mx-auto px-4 md:px-0">
                  <p className="text-4xl text-white font-semibold leading-tight">
                    Admin Dashboard
                  </p>
                  <p className="text-xl text-white leading-none">
                    Be responsible. Your decisions are recorded.
                  </p>
                  <p className="mt-4 text-white">
                    Email <a href="mailto:airfan2@hwemail.com" class="text-white underline" target="_blank">airfan2@hwemail.com</a> for any questions.

                  </p>
                </div>
              </div>
              <div className="max-w-3xl mx-auto mt-4 px-4 md:px-0 -my-4">
                <div className="pt-6">
                  {/* <div className="-my-8"> */}
                  <div class="flex -mx-2 -my-2 pb-2">
                    <a href="/admin/ideas" className="w-full py-12 px-6 border-2 border-gray-200 bg-gray-200 mx-2 my-2 hover:border-gray-900 border-transition-7 flex items-center justify-center ">
                      <div>
                        <p className="text-gray-900 font-semibold text-xl">
                          Review Ideas
                          <i className="fas fa-angle-right ml-2"></i>
                        </p>
                      </div>
                    </a>

                    <a href="/admin/topics" className="w-full py-12 px-6 border-2 border-gray-200 bg-gray-200 mx-2 my-2 hover:border-gray-900 border-transition-7 flex items-center justify-center ">
                      <div>
                        <p className="text-gray-900 font-semibold text-xl">
                          Review Topics
                          <i className="fas fa-angle-right ml-2"></i>
                        </p>
                      </div>
                    </a>

                  </div>
                  <div class="flex -my-2 -mx-2">

                    <a href="/admin/newsletter" className="w-full py-12 px-6 border-2 border-gray-200 bg-gray-200 mx-2 my-2 hover:border-gray-900 border-transition-7 flex items-center justify-center ">
                      <div>
                        <p className="text-gray-900 font-semibold text-xl">
                          Publish Newsletter
                          <i className="fas fa-angle-right ml-2"></i>
                        </p>
                      </div>
                    </a>

                    <a href="/admin/request" className="w-full py-12 px-6 border-2 border-gray-200 bg-gray-200 mx-2 my-2 hover:border-gray-900 border-transition-7 flex items-center justify-center ">
                      <div>
                        <p className="text-gray-900 font-semibold text-xl">
                          Request Admin
                          <i className="fas fa-angle-right ml-2"></i>
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
    )
  }
      </div>
    );
  }
}

export default AdminDashboard;
