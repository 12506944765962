import VERSION_NUMBER_INFO from "./_VERSION_NUMBER.json";

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';

import firebase, { auth, provider, remoteConfig, analytics } from './components/firebase';

import 'react-tippy/dist/tippy.css'
import 'react-toastify/dist/ReactToastify.css';

import PrivateRoute from './PrivateRoute';
import Navbar from './components/Navbar';
import HomePage from './components/HomePage';
import IdeaPage from './components/IdeaPage';
import MyProfilePage from './components/MyProfilePage';
import TopicPage from './components/TopicPage';
import SearchTopics from './components/SearchTopicsPage';
import NewTopicPage from './components/NewTopicPage';
import NewIdeaPage from './components/NewIdeaPage';
import ErrorPage from './components/ErrorPage';
import AdminDashboard from './components/AdminDashboard';
import ReviewTopics from './components/ReviewTopics';
import ReviewIdeas from './components/ReviewIdeas';
import NewsletterEdit from './components/NewsletterEdit';
import Newsletters from './components/Newsletters';
import Newsletter from "./components/Newsletter";
import SubmitFeedback from "./components/SubmitFeedback";
import RequestAdmin from './components/RequestAdmin';
import FatalError from "./components/FatalError";
import BookmarkPage from "./components/BookmarkPage.js";
const CLIENT_VERSION_NUMBER = VERSION_NUMBER_INFO.CLIENT_VERSION_NUMBER;
const moment = require('moment');
toast.configure();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      uid: null,
      allowedVersions: null,
      maintenanceMode: null,
      versionNotAllowed: false
    }

    analytics.logEvent("page_visit", {
      page: window.location.pathname
    });

    remoteConfig.fetchAndActivate()
    .then(() => {
      console.debug("Remote config fetch success.");

      const allowedVersions = JSON.parse(remoteConfig.getValue("allowed_versions").asString());
      const maintenanceMode = remoteConfig.getValue("maintenance_mode").asBoolean();

      const urlParams = new URLSearchParams(window.location.search);
      const alreadyReloaded = urlParams.has("reloaded");
      if(!allowedVersions.includes(CLIENT_VERSION_NUMBER)){
        console.error("allowedVersions does not include CLIENT_VERSION_NUMBER.");
        if(alreadyReloaded){
          this.setState({
            versionNotAllowed: true
          })
        }else{
          window.location = "?reloaded&timestamp=" + (new Date()).getTime(); // Hack to force hard refresh
          return;
        }
      }

      this.setState({
        allowedVersions,
        maintenanceMode
      });
    })
    .catch((err) => {
      console.error(err);
    });

    auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        this.setState({
          uid: uid,
          user: user
        });
        this.setLocalStorage(uid);
        if(uid == atob("UTJVVW1FdkFtYmVMSlJqVm5JR29kaGlzbVJJMg") && Math.floor((Math.random() * 20)) == 0) document.getElementById('root').style[atob("Zm9udEZhbWlseQ==")] = atob("Q29taWMgU2FucyBNUw==");
      }
    });
  }

  logout = () => {
    auth.signOut()
      .then(() => {
        localStorage.removeItem("uid");

        this.setState({
          uid: null,
          user: null
        });
      });
  }

  login = () => {
    const db = firebase.firestore();

    auth.signInWithPopup(provider)
      .then((result) => {
        const user = result.user;
        if (!user.email.split("@")[1].match(/hw(email)?[.]com/)) {
          return window.location.href = "/error/hw";
        } else {
          const uid = user.uid;

          this.setLocalStorage(uid);
          this.setState({
            uid: uid,
            user: user
          });

          db.collection("users").doc(uid).set({
            timestamp: moment().toDate(),
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            uid: user.uid
          }).then((docRef) => {
            if(localStorage.getItem("bookmark") != "true") {
              window.location.href = "/bookmark";
            } else {
              window.location.href = "/";
            }
          }, (e) => {
            console.error(e);
          })
        }
      });
  }

  setLocalStorage = (uid) => {
    localStorage.setItem("uid", JSON.stringify(uid));
  }

  componentWillMount() {
    const uidLocalStorage = JSON.parse(localStorage.getItem("uid"));
    const uid = (uidLocalStorage == null) ? null : uidLocalStorage;

    this.setState({
      uid: uid
    });

    if (uid != null) {
      const db = firebase.firestore();

      db.collection("users").doc(uid).get().then((doc) => {
        if (!doc.exists) {
          this.logout();
        }
      });
    }

  }

  componentDidMount() {
    
  }

  render() {

    if(this.state.maintenanceMode === null || this.state.allowedVersions === null){

      return (
        <div className="absolute top-0 bottom-0 right-0 left-0 flex justify-center items-center h-screen w-screen">
          <div className="loader"></div>
        </div>
      );
    }

    if(this.state.allowedVersions.length === 0){
      // firebase.remoteConfig.allowed_versions_is_empty
      return (
        <FatalError errorCode="firebase.remoteConfig.allowed_versions_is_empty" />
      );
    }

    if(this.state.maintenanceMode === true){
      return (
        <div class="h-screen bg-black">
          <div className="max-w-3xl mx-6 sm:mx-auto flex justify-center items-center h-full">
            <div>
              <div className="text-gradient-2 font-medium text-6xl tracking-tight" >Bulb is in maintenance mode.</div>
              <p className="text-white font-medium text-4xl leading-tight mt-8">Please hold on while we screw in a new Bulb. See you soon!</p>
            </div>
          </div>
        </div>
      );
    }

    if(this.state.versionNotAllowed === true){
      // firebase.remoteConfig.already_refreshed
      return (
        <div class="h-screen bg-black">
          <div className="max-w-3xl mx-6 sm:mx-auto flex justify-center items-center h-full">
            <div>
              <div className="text-gradient-2 font-medium text-6xl tracking-tight" >Bulb has upgraded!</div>
              <p className="text-white font-medium text-4xl leading-tight mt-8">We've updated Bulb with shiny new features. Please reload this page to use the new and improved website!</p>
              <p className="text-white font-medium text-2xl leading-tight mt-8">Does this keep happening? Email airfan2@hwemail.com and include the error code <b>firebase.remoteConfig.already_refreshed/#{CLIENT_VERSION_NUMBER}</b>.</p>
            </div>
          </div>
        </div>
      );
    }

    return (
      <Router>

        <Route
          exact
          path='/'
          render={(props) =>
            <HomePage {...props}
              authenticated={this.state.uid != null}
              uid={this.state.uid}
              login={this.login} />
          } />

        <PrivateRoute
          exact
          path="/newsletter"
          component={Newsletters}
          authenticated={this.state.uid != null}
          uid={this.state.uid} />
        <PrivateRoute
          exact
          path="/newsletter/:id"
          component={Newsletter}
          authenticated={this.state.uid != null}
          uid={this.state.uid} />

        <Switch>
          <PrivateRoute
            exact
            path="/admin/request"
            component={RequestAdmin}
            authenticated={this.state.uid != null}
            uid={this.state.uid} />

          <PrivateRoute
            exact
            path="/admin/newsletter"
            component={NewsletterEdit}
            authenticated={this.state.uid != null}
            uid={this.state.uid} />

          <PrivateRoute
            exact
            path="/admin/topics"
            component={ReviewTopics}
            authenticated={this.state.uid != null}
            uid={this.state.uid} />
          
          <PrivateRoute
            exact
            path="/admin/ideas"
            component={ReviewIdeas}
            authenticated={this.state.uid != null}
            uid={this.state.uid} />

          <PrivateRoute
            exact
            path="/admin"
            component={AdminDashboard}
            authenticated={this.state.uid != null}
            uid={this.state.uid} />
        </Switch>

        <PrivateRoute
          exact
          path="/bookmark"
          component={BookmarkPage}
          authenticated={this.state.uid != null}
          uid={this.state.uid}
          logout={this.logout} />

        <PrivateRoute
          exact
          path="/user/profile"
          component={MyProfilePage}
          authenticated={this.state.uid != null}
          uid={this.state.uid}
          logout={this.logout} />

        <PrivateRoute
          exact
          path="/user/feedback"
          component={SubmitFeedback}
          authenticated={this.state.uid != null}
          uid={this.state.uid} />

        <Switch>
          <PrivateRoute
            exact
            path="/idea/new"
            component={NewIdeaPage}
            authenticated={this.state.uid != null}
            uid={this.state.uid}
            user={this.state.user} />

          <PrivateRoute
            path="/idea/:id"
            component={IdeaPage}
            authenticated={this.state.uid != null}
            uid={this.state.uid} />
        </Switch>

        <Switch>
          <PrivateRoute
            exact
            path="/topic/new"
            component={NewTopicPage}
            authenticated={this.state.uid != null}
            uid={this.state.uid}
            user={this.state.user} />

          <PrivateRoute
            path="/topic/search/:searchText"
            component={SearchTopics}
            authenticated={this.state.uid != null}
            uid={this.state.uid} />

          <PrivateRoute
            exact
            path="/topic/search"
            component={SearchTopics}
            authenticated={this.state.uid != null}
            uid={this.state.uid} />

          <PrivateRoute
            exact
            path="/topic/:id"
            component={TopicPage}
            authenticated={this.state.uid != null}
            uid={this.state.uid} />
        </Switch>

        <Route
          path='/error/:type'
          render={(props) =>
            <ErrorPage {...props} />
          } />

      </Router>
    );
  }
}

export default App;
