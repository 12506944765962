import React from "react";

export default class Divider extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    
    render(){
        return (
            <div className={"w-full border-t border-t-gray-600 " + this.props.className} style={this.props.style} />
        );
    }
}