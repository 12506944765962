import React, { Component } from 'react';
import Navbar from './Navbar';

class SubmitFeedback extends Component {
  render() {

    return (
      <div className=''>
        <Navbar />

        <div className="max-w-3xl mx-auto pt-6 px-4 md:px-0">
          <div class="py-4">
          <iframe class="w-full" src="https://docs.google.com/forms/d/e/1FAIpQLSfOWPVSXEkCU8Z8iQ7qmz72I2g1Po3r2oRVRVE23lXxwJFgLQ/viewform?embedded=true" height="861" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
          </div>
        </div>
      </div>
    )
  }
}

export default SubmitFeedback;