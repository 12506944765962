import React, { Component } from 'react';
import firebase, {analytics} from "./firebase";

const SortButton = (props) => {
  let buttonClass;
  if(props.indigo === true){
    buttonClass = "px-3 py-1 tracking-wide rounded-full text-xs font-bold uppercase ";
    buttonClass += props.selected ? "bg-indigo-700 hover:bg-indigo-500 text-white" : "hover:bg-indigo-100 text-indigo-700";
  }else{
    buttonClass = "px-3 py-1 tracking-wide rounded-full text-xs font-bold uppercase ";
    buttonClass += props.selected ? "bg-gray-900 hover:bg-black text-white" : "hover:bg-gray-200 text-black";
  }
  return (
    <button className={buttonClass} onClick={() => {
      props.changeSort(props.sort);
      analytics.logEvent("sort_by", {
        uid: props.uid,
        sortMethod: props.sort,
        page: window.location.pathname
      });
    }} >
      <i className={"fas mr-1 fa-" + props.fa}></i>
      {props.text}
    </button>
  )
}

export default SortButton;