import React from "react";

/**
 * @param topic Topic data, plus the field `id` set to the doc id.
 */
export default class Topic extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    
    render(){
        const {topic} = this.props;

        return (
            <div className="flex p-5 my-4 cursor-pointer shadow hover:bg-gray-100 bg-transition" onClick={() => { window.location.href = "/topic/" + topic.id }}>
                <div className="flex-1">
                    <p className="text-lg font-semibold text-gray-700 leading-tight mr-1">
                    {topic.name}
                    </p>
                    <p className="flex text-gray-500 break-word leading-tight mt-1 mr-1">
                    {topic.description}
                    </p>
                </div>

                <div className="flex justify-center items-center px-2">
                    <i className="fas fa-angle-right text-lg text-gray-700"></i>
                </div>
            </div>
        );
    }
}