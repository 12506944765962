import React, { Component } from 'react';
import IdeaList from './IdeaList';
import Navbar from "./Navbar";
import firebase from "./firebase";

const moment = require('moment')

class TopicPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tid: this.props.match.params.id,
      topic: null,
      dataPulled: false
    };
  }

  componentDidMount() {
    const db = firebase.firestore();
    const tid = this.state.tid;

    db.collection("topics").doc(tid).get().then((doc) => {
      if (doc.exists && doc.data().approved) {
        let topic = doc.data();
        topic.timestamp = topic.timestamp.toDate();

        this.setState({ topic: topic, dataPulled: true });

      } else {
        window.location.href = "/error/404"
      }
    });
  }

  render() {
    const topic = this.state.topic;

    return (
      <div>
        {topic == null ?
          <div class="absolute top-0 bottom-0 right-0 left-0 flex justify-center items-center h-screen w-screen">
            <div class="loader"></div>
          </div>
          :
          <div>
            <Navbar />


            <div className="top-0 bg-white border-b">
              <div class="bg-indigo-600 h-5">
              </div>
              <div class="max-w-3xl mx-auto py-5 px-4 lg:px-0">
                <div class="flex justify-between">
                  <div>
                    <div>
                      <p className="center text-4xl leading-tight text-black font-semibold title-font">
                        {topic.name}
                      </p>
                    </div>
                    <div className="mb-2 pt-1">
                      <p className="text-gray-600 font-light text-lg">
                        {topic.description}
                      </p>
                    </div>
                  </div>


                </div>
                <div class="flex-no-wrap">
                  <a class="font-medium text-gray-500 text-transition text-sm hover:text-gray-700  " href={"mailto:" + topic.creator.email} traget="_blank">Contact owner</a>
                  <p class="font-medium text-gray-500 text-sm">
                    Created {moment(topic.timestamp).fromNow()}
                  </p>
                </div>

              </div>
            </div>

            <div className="max-w-3xl mx-auto pt-4 px-4 lg:px-0 items-start">
              <div className="w-full pt-4">
                <div class="">
                  <IdeaList type="topic" uid={this.props.uid} tid={this.state.tid} />
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default TopicPage;
