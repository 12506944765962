import React from "react";
import firebase from "./firebase";
import Navbar from './Navbar';

import { Tooltip } from 'react-tippy';

const moment = require("moment")

export default class Newsletters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataPulled: false,
      newsletters: null,
      admin: false
    }

    this.db = firebase.firestore();

    this.getNewsletters();
    this.checkAdmin();
  }

  checkAdmin() {
    this.db.collection("global").doc("admins").get().then((doc) => {
      if (doc.exists) {
        this.setState({
          admin: doc.data().admins.includes(this.props.uid)
        });
      }
    })
  }

  getNewsletters() {
    this.db.collection("newsletters").where("deleted", "==", false).orderBy("timestamp", "desc").get().then((querySnapshot) => {
      let newsletters = [];

      querySnapshot.forEach((doc) => {
        const newsletter = doc.data();
        newsletter.timestamp = newsletter.timestamp.toDate();
        newsletter.id = doc.id;
        newsletters.push(newsletter);
      });

      this.setState({ newsletters: newsletters, dataPulled: true });
    });
  }

  render() {
    if (!this.state.dataPulled) {
      return (
        <div className="absolute top-0 bottom-0 right-0 left-0 flex justify-center items-center h-screen w-screen">
          <div className="loader"></div>
        </div>
      );
    }
    return (
      <div>
        <div className="">
          <Navbar type="dark" />

          <div className="bg-gray-100">
            <div className="max-w-3xl mx-auto px-4 md:px-0 py-20 md:py-28 ">
              {/* <a href="/" className="tracking-wide uppercase text-sm mb-2 text-indigo-100 hover:underline font-semibold">
                  <i className="fas fa-angle-left mr-1"></i>
                  Return
                </a> */}

              <div className="mt-3">
                <div>
                  <p className="text-4xl font-semibold leading-tight text-black">Student Council Newsletters</p>
                  <p className="text-lg text-gray-600 mt-2">This with you frequently written by our very own student council. Student Council releases monthly newsletters on updates, projects, and more. We are excited to be sharing this with you frequently written by our very own student council.</p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="h-4 bg-gray-100"></div> */}
          <div className="max-w-3xl mx-auto px-6 md:px-0 mt-8">


            <div className="mt-10 pb-10">
              {this.state.newsletters.map((newsletter) => {
                return (
                  <a href={"/newsletter/" + newsletter.id} key={newsletter.id}>
                    <div className="border-t border-b py-10 border-gray-200 hover:border-black border-transition-7 cursor-pointer">
                      <div>
                        <div className="flex justify-between items-center">
                          <p className="font-semibold text-indigo-600 text-sm uppercase tracking-wide leading-tight">
                            {moment(newsletter.timestamp).format('LL')}
                          </p>

                          {/* {!this.state.admin ? "" :
                            <Tooltip
                              position="bottom"
                              theme="light"
                              // size="big"
                              arrow="true"
                              // trigger="click"
                              distance="10"
                              interactive
                              html={(
                                <div className="px-3 py-2">
                                  <button className="text-md font-semibold text-gray-600 hover:text-gray-800 block py-1" onClick={() => {
                                    this.db.collection("newsletters").doc(newsletter.id).update({
                                      deleted: true,
                                      deletedBy: {
                                        uid: this.props.uid
                                      }
                                    });
                                    this.state.newsletters.forEach((newsletterForEach, i) => {
                                      if (newsletterForEach.id == newsletter.id) {
                                        console.log(newsletter.title)
                                        console.log(this.state.newsletters)
                                        let x = this.state.newsletters;
                                        x.splice(i, 1);
                                        console.log(x)
                                        this.setState({
                                          newsletters: x
                                        });
                                      }
                                    })
                                  }}>
                                    <i className="fas fa-trash mr-2"></i>
                                    Delete Newsletter
                                </button>
                                </div>
                              )}>
                              <button className="px-2 text-gray-500">
                                <i className="fas fa-ellipsis-v"></i>
                              </button>
                            </Tooltip>
                          } */}

                        </div>
                        <p className="font-semibold text-black text-2xl capitalize mt-3 mb-2 leading-tight">
                          {newsletter.title}
                        </p>
                        <p className="text-gray-500 text-xl leading-tight">
                          {newsletter.description}
                        </p>
                      </div>
                    </div>
                  </a>
                )
              })}
            </div>

          </div>

        </div>
      </div>
    );
  }
}