import React, { Component } from 'react';
import Navbar from "./Navbar";
import firebase, { analytics } from "./firebase";
import { getPopularTopics } from "../helpers";

import $ from 'jquery';
import Topic from './Topic';

// How many popular topics to show.
const POPULAR_TOPICS_COUNT = 5;


class SearchTopics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputFocused: false,
      searchText: "",
      topics: [],
      suggestedTopics: [],
      dataPulled: true,
      typing: false
    };

    this.db = firebase.firestore();
    this.fetchSuggestedTopics();

  }

  fetchSuggestedTopics = async () => {
    const suggestedTopics = await getPopularTopics(POPULAR_TOPICS_COUNT);
    this.setState({ suggestedTopics });
  }

  searchTopics = () => {

    let searchText = this.state.searchText.toLowerCase();

    analytics.logEvent("topic_search", {
      uid: this.props.uid,
      query: searchText
    });

    this.db.collection("topics").where("approved", "==", true).where("searchTerms", "array-contains", searchText).get().then((querySnapshot) => {
      let topics = [];
      querySnapshot.forEach((doc) => {
        const topic = doc.data();

        const topicSearchObj = {
          name: topic.name,
          description: topic.description,
          id: doc.id
        }

        topics.push(topicSearchObj);
      });

      this.setState({
        topics: topics,
        dataPulled: true
      });
    });
  }

  handleForm = (e) => {
    e.preventDefault();

    this.searchTopics();
  }

  handleChange = (event) => {
    const value = event.target.value;

    this.setState({
      searchText: value,
      dataPulled: false
    });
  }

  shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }


  clearQuery = () => {
    this.setState({ searchText: "" })
  }

  onFocus = () => {
    this.setState({ inputFocused: true })
  }

  onBlur = () => {
    this.setState({ inputFocused: false })
  }

  componentDidMount() {
    $(".loading").slideUp();
  }

  render() {

    return (
      <div>
        <Navbar />

        <div className='mt-8'>
          <div className="max-w-3xl mx-auto px-10 md:px-0">
            <p className="text-4xl leading-none mb-3 font-medium">Find communities for you.</p>
            <p className="text-lg mb-6 font-light text-gray-600">Topics allow students to view ideas that belong to a communities. Some examples are "Libary", "Cafeteria", or even a specific club. Search here to find some that interest you, and cast your vote by liking ideas!</p>
            <form onSubmit={this.handleForm} className={"border-transition-7 flex items-center justify-between border " + (this.state.inputFocused ? "border-indigo-800" : "border-gray-400")}>
              <input className="p-5 w-full outline-none text-xl" onFocus={this.onFocus} onBlur={this.onBlur} onChange={this.handleChange} type="text" placeholder="Search Topics" value={this.state.searchText} />
              {/* <button type="submit">Search </button> */}
              {this.state.searchText == "" ? "" :
                <button className="pr-5 cursor-pointer" type="submit">
                  <i className="fas fa-search"></i>
                </button>
              }
            </form>

            <div className="py-3 -my-4">
              <div className="">
                {this.state.searchText == "" ? (
                  <div>
                    <p className="uppercase font-bold text-indigo-400 text-sm mt-6 -mb-2">Suggested</p>
                    {
                      this.state.suggestedTopics.map((topic) => {
                        return (
                          <Topic topic={topic} />
                        )
                      })
                    }
                  </div>
                ) : (this.state.topics.length == 0 && this.state.dataPulled) ?
                    <div className="bg-white p-5 text-gray-500 font text-lg shadow my-4 text-center">No topics found</div>
                    :
                    this.state.topics.map((topic) => {
                      return (
                        <Topic topic={topic} />
                      )
                    })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchTopics;
