import firebase, { analytics } from "./components/firebase";

/**
 * Gets the most popular topics, determined by the number of ideas categorized under the topic.
 * @returns {Array.<Object.<string, any>>} An array of topics.
 * @param {number} length How many topics to retrieve.
 */
export async function getPopularTopics(length) {
  const db = firebase.firestore();

  const querySnapshot = await db.collection("topics").where("approved", "==", true).orderBy("ideasSubmitted", "desc").limit(length).get();

  const topics = [];
  querySnapshot.forEach((doc) => {
    let x = doc.data();
    x.id = doc.id;
    topics.push(x);
  });
  return topics;
}
