import React, { Component } from 'react';
import Navbar from './Navbar';

class RequestAdmin extends Component {
  render() {

    return (
      <div className=''>
        <Navbar />

        <div className="max-w-3xl mx-auto pt-6 px-4 md:px-0">
          <div class="py-4">
          <iframe class="w-full" src="https://docs.google.com/forms/d/e/1FAIpQLSePH49Y41intE1o7p0z7tcExwx9ttJ-1o78L3kx3lH3KqfDHw/viewform?embedded=true" height="1000" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
          </div>
        </div>
      </div>
    )
  }
}

export default RequestAdmin;