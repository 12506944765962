import React, { Component } from 'react';
import Navbar from './Navbar';

class BookmarkPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      checkLS: false
    }

  }

  componentDidMount() {
    if (localStorage.getItem("bookmark") == "true") {
      window.location.href = "/"
    }
    this.setState({ checkLS: true })
  }

  render() {
    if (this.state.checkLS == false) {
      return (
        <div className="absolute top-0 bottom-0 right-0 left-0 flex justify-center items-center h-screen w-screen">
          <div className="loader"></div>
        </div>
      )
    }



    return (
      <div className='top-0 bottom-0 right-0 left-0 bg-indigo-100'>
        <div class="flex justify-center items-center h-screen">
          <div class="bg-white p-10 shadow-2xl">
            <div>
              <p class="text-3xl font-medium leading-tight">Bookmark this page!</p>
              <p class="text-lg text-gray-500">Bookmark this page so it's always easily accesible.</p>
            </div>
            <div class="my-5 border shadow">
              <img src="https://media.giphy.com/media/jn2LeLktNIcQqmFNMY/giphy.gif" />
            </div>
            <div class="flex justify-center">
              <a href="/" onClick={() => {
                this.setState({ loading: true })
                localStorage.setItem("bookmark", true);
              }} class="mt-3 font-semibold py-5 px-5 w-full text-center bg-transition text-white bg-indigo-600 hover:bg-indigo-700 text-lg">
                {!this.state.loading ?
                  <p>I've bookmarked this page. Take me to HW Bulb! <i class="ml-2 fas fa-angle-right"> </i></p>
                  :
                  "Loading..."
                }
              </a>
            </div>
          </div>
        </div>
      </div >
    )
  }
}

export default BookmarkPage;