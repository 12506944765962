import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/remote-config';
import 'firebase/analytics';

const config = {
  apiKey: "AIzaSyBDlLY3EVFo6LOt05agNr0UvcT_8MVJ5Rg",
  authDomain: "bulbr-581c5.firebaseapp.com",
  databaseURL: "https://bulbr-581c5.firebaseio.com",
  projectId: "bulbr-581c5",
  storageBucket: "bulbr-581c5.appspot.com",
  messagingSenderId: "316959042213",
  appId: "1:316959042213:web:bbf447417d1541d4fe7b4c",
  measurementId: "G-Y3Z01QFZ3H"
};
firebase.initializeApp(config);

export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export const remoteConfig = firebase.remoteConfig();
export const analytics = firebase.analytics();

remoteConfig.settings = {
  fetchTimeoutMillis: 120000,
  minimumFetchIntervalMillis: process.env.NODE_ENV == "development" ? 10000 : 1800000
};
remoteConfig.defaultConfig = ({
  "allowed_versions": [],
  "maintenance_mode": true
});
export default firebase;