import React from "react";

export default class FatalError extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div class="h-screen bg-black">
                <div className="max-w-3xl mx-6 sm:mx-auto flex justify-center items-center h-full">
                    <div>
                        <div className="text-gradient-2 font-medium text-6xl tracking-tight" >Something went wrong.</div>
                        <p className="text-white font-medium text-4xl leading-tight mt-8">We're sorry about that. Please contact airfan2@hwemail.com about this issue.</p>
                        <p className="text-white font-medium text-2xl leading-tight mt-8">In your email, please include the error code <b>{this.props.errorCode}</b></p>
                    </div>
                </div>
            </div>
        );
    }
}