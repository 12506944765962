import React, { Component } from 'react';
import firebase, { analytics } from "./firebase";
import Navbar from './Navbar';
import { Tooltip } from "react-tippy"
import moment from 'moment';


class ReviewTopics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      admin: false,
      topics: null,
      loading: false,
      rejectText: ''
    };

    this.db = firebase.firestore();

    this.checkAdmin();
    this.getTopics();

  }

  handleChange = (e) => {
    this.setState({
      rejectText: e.target.value
    })
  }

  handleClick = (approved, id, reason) => {
    this.setState({ loading: true });
    let thisTopic;
    this.state.topics.forEach((topic, i) => {
      if (topic.id == id) {
        thisTopic = topic;
      }
    });

    let reviewedBy = {
      uid: this.props.uid
    }

    if (!approved) {
      reviewedBy.reason = reason;
    }

    analytics.logEvent("admin_review_topic", {
      id,
      admin: this.props.uid,
      approved,
      reason: approved ? "approved" : reason,
      creator: thisTopic.creator.uid
    });

    this.db.collection("topics").doc(id).update({
      reviewed: true,
      approved: approved,
      reviewedBy: reviewedBy
    }).then(() => {
      let cloudfn = firebase.functions().httpsCallable('sendTopicEmailMessage');
      cloudfn({
        to: thisTopic.creator.email,
        name: thisTopic.name,
        reason: (approved ? "approved" : reason),
        approved: approved
      }).then(() => {
        this.setState({ loading: false });
        this.getTopics();
      }, (e) => {
        console.error(e);
      });
    });


  }

  getTopics = () => {
    this.db.collection("topics").where("reviewed", "==", false).orderBy("timestamp", "asc").get().then((querySnapshot) => {
      let topics = querySnapshot.docs.map((topic) => {
        let x = topic.data();
        x.timestamp = x.timestamp.toDate();

        x.id = topic.id;
        return x;
      })

      this.setState({
        topics: topics
      });
    })
  }

  checkAdmin = () => {
    const db = firebase.firestore();
    db.collection("global").doc("admins").get().then((doc) => {
      if (doc.exists) {
        if (doc.data().admins.includes(this.props.uid)) {
          this.setState({ admin: true });
        } else {
          alert("You are not an admin");
          window.location.href = "/";
        }
      }
    });
  }

  render() {
    return (
      <div>
        {(!this.state.admin || this.state.topics == null || this.state.loading) ?
          <div className="absolute top-0 bottom-0 right-0 left-0 flex justify-center items-center h-screen w-screen">
            <div className="loader"></div>
          </div>
          :
          <div>
            <Navbar />

            <div className="py-20 bg-gray-100">
              <div className="max-w-3xl mx-auto px-4 md:px-0">

                <p className="text-4xl text-gray-900 font-semibold">
                  Review Topics
                </p>

                <ul className="text-xl text-gray-600 list-decimal pl-5 mt-2">
                  <li>Check title and description, and all necessary reviews.</li>
                  <li>Make sure it is an original topic.</li>
                </ul>
              </div>
            </div>

            <div className="max-w-3xl mx-auto px-4 md:px-0 py-10 min-h-screen -my-6">
              {this.state.topics.length == 0 ? <div className="bg-whtie shadow p-5 text-gray-500 text-lg text-center">No topics found.</div> : this.state.topics.map((topic) => {
                return (
                  <div className="my-6 p-5 border border-gray-300 text-left">
                    <p className="text-gray-500 mb-1">{moment(topic.timestamp).fromNow()}</p>
                    <p className="text-gray-500 mb-1">Created by {topic.creator.displayName}</p>
                    <p className="text-3xl leading-tight font-medium">{topic.name}</p>
                    <p className="text-gray-800 text-xl">{topic.description}</p>

                    <div className="flex mt-4 justify-center -mx-2">
                      <button className="mx-2 text-2m bg-gray-100 text-green-500 font-bold rounded-full hover:bg-gray-200 py-2 px-6 inline-flex items-center" onClick={() => { this.handleClick(true, topic.id) }}>
                        <i className="fas fa-check"></i>
                      </button>

                      <Tooltip
                        position="bottom"
                        arrow="true"
                        trigger="click"
                        theme="light"
                        interactive
                        html={(
                          <div className="px-3 py-2">
                            <form onSubmit={() => { this.handleClick(false, topic.id, this.state.rejectText) }} className="px-3 py-2">
                              <input required value={this.state.rejectText} onChange={this.handleChange} name="reason" class="w-full rounded outline-none px-3 py-2 border border-gray-300 focus:border-gray-600 border-transition-7 bg-gray-100 focus:bg-white" placeholder="Reason (spam, offensive, etc.)" />
                              <button class="text-white mt-2 w-full px-3 py-2 bg-red-500 hover:bg-red-700 font-semibold bg-transition rounded" type="submit">Reject Topic</button>

                            </form>
                          </div>
                        )}>
                        <button className="mx-2 text-2m bg-gray-100 text-red-600 font-bold rounded-full hover:bg-gray-200 py-2 px-6 inline-flex items-center">
                          <i className="fas fa-trash"></i>
                        </button>
                      </Tooltip>

                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        }
      </div>
    );
  }
}

export default ReviewTopics;
