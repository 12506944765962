import React from "react";
import Idea from "./Idea";

/**
 * Maximum number of times it will try to get a random Idea.
 * @type {number}
 */
const MAX_TRIES = 3;

/**
 * Fetches and returns a random Idea.
 * @param {firebase.firestore.Firestore} db Firestore database object
 * @param {string} uid UID of the currently authenticated user
 * @param {string} displayedIdeas An array of the IDs of the ideas already displayed in the list, to prevent duplicates
 */
export default class DiscoverIdea extends React.Component {

    constructor(props){
        super(props);
        this.state = {idea: null, failed: false};
        this.refreshDisplayed();
    }

    refreshDisplayed = () => {
        this.getRandomIdea((doc) => {
            let idea = doc.data();
            idea.id = doc.id;
            idea.timestamp = idea.timestamp.toDate();
            idea.liked = idea.likers.includes(this.uid);
            
            // TODO: Consolidate "get topic" calls into a functions
            this.props.db.collection("topics").doc(idea.topic.id).get().then((docTopic) => {
                if (docTopic.exists) {
                    const topic = docTopic.data();
                    idea.topic.name = topic.name;
                }
            }, (e) => {
                console.error(e);
            }).then(() => {
                this.setState({idea});
            }, (e) => {
                console.error(e);
            });

        });
    }

    getRandomIdea = (callback, reverse = false, random = -1, tries = 0) => {
        console.debug("Getting random idea... try: " + tries);
        if(tries > MAX_TRIES){
            console.debug("Get random idea failed! Out of tries.");
            this.setState({failed: true});
            return;
        }
        let ideasRef = this.props.db.collection("ideas").where("flagged", "==", false).where("deleted", "==", false);
        if (random == -1) random = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
        
        if (reverse) {
            ideasRef.where("randomId", "<=", random).orderBy("randomId", "desc").limit(1).get().then((snap) => {
                if(snap.empty) {
                    console.debug("Snap empty, reverse");
                    this.getRandomIdea(callback, false, -1, tries + 1);
                }else{
                    snap.forEach((doc) => {
                        if(this.props.displayedIdeas.includes(doc.id)){
                            console.debug("Random idea already displayed! Trying again.");
                            this.getRandomIdea(callback, false, -1, tries + 1);
                        }else{
                            callback(doc);
                        }
                    });
                }
            }, (e) => {
                console.error(e);
            });
        } else {
            ideasRef.where("randomId", ">", random).orderBy("randomId").limit(1).get().then((snap) => {
                if (snap.empty) {
                    console.debug("Snap empty, non reverse");
                    this.getRandomIdea(callback, true, random, tries);
                } else {
                    snap.forEach((doc) => {
                        if(this.props.displayedIdeas.includes(doc.id)){
                            console.debug("Random idea already displayed! Trying again.");
                            this.getRandomIdea(callback, false, -1, tries + 1);
                        }else{
                            callback(doc);
                        }
                    });
                }
            }, (e) => {
                console.error(e);
            });
        }
    }

    render(){
        

        if(this.state.failed) return null;

        if(this.state.idea == null){
            return (
                // <div className="h-48 px-5 pb-5 pt-3 bg-gray-100 border border-indigo-500" id="discovery-idea-loading">
                //     <div className="absolute top-0 bottom-0 right-0 left-0 flex justify-center items-center">
                //         <div className="loader"></div>
                //     </div>
                // </div>
                // <div><div class="loader"></div></div>
                <div class="h-40 bg-gray-100">
                    <div class="flex justify-center items-center h-full">
                        <p class="text-gray-300 text-4xl font-medium">For you...</p>
                    </div>
                </div>
            );
        }

        if(this.props.displayedIdeas.includes(this.state.idea.id)){
            // If the props have changed so that the displayed idea is no longer unique, refresh.
            this.refreshDisplayed();
        }

        return (
            <Idea db={this.props.db} idea={this.state.idea} uid={this.props.uid} showTopicBtn={true} discovery={this.props.normalStyling !== true}/>
        );
    }

}