import React, { Component } from 'react';

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: ""
    }
  }

  handleSearch = (event) => {
    event.preventDefault();

    window.location.href = "/topic/search/" + this.state.searchText;
  }

  handleChange = (event) => {
    this.setState({ searchText: event.target.value });
  }

  render() {
    return (
      // <div className="border-b-2 border-gray-200 hover:border-gray-400 border-transition-7 py-1">
      <div className="py-1 top-0 sticky bg-white shadow">
        <header className="max-w-3xl mx-auto flex justify-between px-4 md:px-0 items-center">
          <a href="/">
            <div className="flex items-center text-gray-800">
              <p className="title-font leading-none font-semibold border-b-2 pb-px border-gray-200 text-lg w-3/4">Bulb</p>
            </div>
          </a>



          {/* <div className="items-center flex" id="menu">
            <form className="text-gray-600" onSubmit={this.handleSearch}>
              <input name="search" placeholder="Search" className="bg-white py-2 pl-3 text-sm focus:outline-none" onChange={this.handleChange} autocomplete="off" />
              <button type="submit" className="text-sm bg-white pl-1 pr-3 py-2">
                <i className="fa-sm fas fa-search text-gray-400 hover:text-gray-500"></i>
              </button>
            </form>
          </div> */}

          <div className="py-2 md:py-0 flex">
            <div className="items-center flex -px-3" id="menu">
              <a className="py-4 px-3 block font-bold text-gray-600 hover:text-gray-800 tracking-wide" href="/idea/new">
                <i className="fas fa-plus mr-1"></i>
              </a>
              <a className="py-4 px-3 block font-bold text-gray-600 hover:text-gray-800 tracking-wide" href="/topic/search">
                <i className="fas fa-search mr-1"></i>
              </a>
              <a className="py-4 px-3 block font-bold text-gray-600 hover:text-gray-800 tracking-wide" href="/user/profile">
                <i className="fas fa-user mr-1"></i>
              </a>
            </div>
            {/* <a href="/user/profile" className="flex items-center pointer-cursor ml-3">
              <img className="rounded-full w-10 h-10 border-2 border-transparent shadow-md hover:shadow-lg" src={this.props.photoURL} alt="Andy Leverenz" />
            </a> */}
          </div>

        </header>
      </div >

    );
  }
}

export default Navbar;
