import React, { Component } from 'react';
import Idea from './Idea';
import firebase from "./firebase"
import Navbar from './Navbar';

class IdeaPage extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      idea: null,
      dataPulled: false,
      iid: this.props.match.params.id
    }

  }

  ideaPulled = (idea) => {
    this.setState({
      dataPulled: true,
      idea: idea
    });
  }

  componentDidMount() {
    const id = this.state.iid;
    const uid = this.props.uid;

    const db = firebase.firestore();
    const docRef = db.collection("ideas").doc(id);

    let idea = null;

    docRef.get().then((doc) => {
      if (doc.exists && doc.data().deleted == false) {
        idea = doc.data();

        idea.id = doc.id;
        idea.timestamp = idea.timestamp.toDate();
        idea.liked = idea.likers.includes(uid);

        db.collection("topics").doc(idea.topic.id).get().then((docTopic) => {
          if (docTopic.exists) {
            const topic = docTopic.data();
            idea.topic.name = topic.name;
          }
        }).then(() => {
          this.ideaPulled(idea);
        });
      } else {
        window.location.href = "/error/404";
      }
    })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
  }

  render() {
    const idea = this.state.idea;

    return (
      <div className=''>
        {idea == null ?
          <div className="absolute top-0 bottom-0 right-0 left-0 flex justify-center items-center h-screen w-screen">
            <div className="loader"></div>
          </div>
          :
          <div>
            <Navbar />
            <div className="max-w-3xl mx-auto pt-8 px-4 md:px-0 pb-10">
              {idea == null ? "" :
                <Idea idea={idea} uid={this.props.uid} type="single" showTopicBtn={true} />
              }
            </div>
          </div>
        }
      </div>
    );
  }
}


export default IdeaPage;
