import React, { Component } from 'react';
import { Tooltip } from 'react-tippy';


export default class LandingPage extends Component {
  render() {
    return (
      <div>
        <div>
          <div class="bg-whit background-img">

            <div class="h-screen">
              {/* <div class="top-0 pt-5 mx-auto px-6 md:px-0 max-w-3xl">
                <div class="flex justify-start -mx-3">
                  <a class="mx-3 font-semibold text-md nav-animation text-white title-font tracking-wide cursor-pointer" href="/#mission">Our mission</a>
                  <a class="mx-3 font-semibold text-md nav-animation text-white title-font tracking-wide cursor-pointer" href="/#features">Features</a>
                  <a class="mx-3 font-semibold text-md nav-animation text-white title-font tracking-wide cursor-pointer" onClick={this.props.login}>Login</a>
                </div>
              </div> */}
              <div class="max-w-3xl mx-6 sm:mx-auto flex justify-center items-center h-full">
                <div>
                  <div>
                    <div className="text-gradient-2 font-medium text-6xl tracking-tight" >HW Bulb.</div>
                    <p className="text-white font-medium text-5xl leading-tight">Redefining the way students voice their opinions.</p>
                  </div>

                  <div>
                    <button className="mt-8 px-10 py-5 text-black bg-white hover:bg-gray-200 font-medium bg-transition text-xl" onClick={this.props.login}>
                      Login with your HW Account
                      <i className="fas fa-angle-right ml-2"></i>
                    </button>
                  </div>
                </div>


              </div>


            </div>

            <div class="text-white text-6xl flex justify-center bottom-0">
              <i class="fas fa-angle-down text-white"></i>
            </div>
          </div>




          <div class="py-32 bg-white" id="mission">
            <div class="max-w-3xl mx-6 sm:mx-auto text-center">
              <p className="font-semibold uppercase tracking-widest text-black text-lg text-gradient">Our Mission</p>
              <p className="font-medium text-5xl leading-tight mt-6">Improving <span class="text-black">Student</span> Life</p>
              <p className="text-gray-600 font-light text-xl mt-6">HW Bulb strives to improve everyday student life for students of Harvard-Westlake. By fostering an online community where students can share and vote on other ideas, we select the best ideas and work with Student Council to implement them in our school.</p>
            </div>
          </div>



          <div class="py-32 bg-gray-100">
            <div class="max-w-3xl mx-6 sm:mx-auto">
              <div class="">
                <p className="font-medium text-5xl leading-none">For <span class="text-gradient">students,</span></p>
                <p className="font-medium text-5xl leading-none">Made by <span class="text-gradient">students.</span></p>
              </div>
              <p className="text-gray-600 font-light text-xl mt-6">We know that students understand other students best. When designing HW Bulb, we took that to heart. Since HW Bulb was fully developed by Harvard-Westlake students, each page and each feature has fellow peers in mind.</p>
            </div>
          </div>

          <div class="hero-img flex justify-center items-center h-full">
            {/* <p class="text-white text-5xl font-medium"></p> */}
          </div>

          <div class="py-32 bg-white" id="features">
            <div class="max-w-3xl mx-6 sm:mx-auto">
              <div class="md:flex md:justify-between md:-mx-3">
                <div class="w-full md:mx-3 text-center">
                  {/* <i className="fas fa-plus text-gray-black text-5xl px-10"></i> */}
                  <img class="w-24 mx-auto mb-6" src={"https://image.flaticon.com/icons/svg/181/181374.svg"} />
                  <p className="font-semibold mb-3 text-3xl">Create</p>
                  <p className="text-gray-600 text-lg leading-relaxed">Create and share your ideas and suggestions to the entire student body.</p>
                </div>
                <div class="w-full md:mx-3 text-center">
                  {/* <i className="fas fa-plus text-gray-black text-5xl px-10"></i> */}
                  <img class="w-24 mx-auto mb-6" src={"https://image.flaticon.com/icons/svg/273/273803.svg"} />
                  <p className="font-semibold mb-3 text-3xl">Vote</p>
                  <p className="text-gray-600 text-lg leading-relaxed">Have an influence on the changes that come to school by placing your vote in different ideas.</p>
                </div>
                <div class="w-full md:mx-3 text-center">
                  {/* <i className="fas fa-plus te3t-gray-black text-5xl px-10"></i> */}
                  <img class="w-24 mx-auto mb-6" src={"https://image.flaticon.com/icons/svg/493/493808.svg"} />
                  <p className="font-semibold mb-3 text-3xl">Observe</p>
                  <p className="text-gray-600 text-lg leading-relaxed">Watch the change that HW Bulb manifests to improve student life.</p>
                </div>
              </div>
            </div>
          </div>

          <div class="py-32 bg-indigo-600 bg-transition hover:bg-indigo-500 cursor-pointer" onClick={this.props.login}>
            <div class="max-w-lg mx-6 sm:mx-auto">
              <p className="text-white text-5xl font-medium leading-none">Join HW Bulb <i class=" fas fa-angle-right ml-1"></i></p>
              <p class="text-xl text-gray-100 mt-3">Login with your HW email and start voicing your suggestions and ideas to the student body.</p>
            </div>
          </div>

          <div class="py-10 bg-gray-900">
            <div class="max-w-3xl mx-6 sm:mx-auto">
              <p className="font-bold text-gray-400 text-lg">HW Bulb &copy;	</p>
              <p className="mt-4 font-light text-md text-gray-500 font-medium">Developed by the
                <span class="ml-1 text-gray-300 hover:text-white text-transition cursor-pointer">
                  <Tooltip 
                    arrow="true" 
                    title="Developed by Aariz Irfan 23' and Eric Yoon 23'">
                    Bulb team.
                  </Tooltip></span>
                </p>
              <a className="font-light text-md text-gray-500 hover:text-gray-300 hover:underline font-medium" href="mailto:airfan2@hwemail.com">Contact us.</a>
            </div>
          </div>


        </div>
      </div>
    );
  }
}


