import React, { Component } from 'react';
import firebase, { analytics } from "./firebase";
import ChooseTopic from "./ChooseTopic";
import Navbar from './Navbar';
import ReactMarkdown from "react-markdown";
import { Tooltip } from 'react-tippy';
import * as qs from 'query-string';

const moment = require('moment');

const commaNumber = require('comma-number')

class NewTopic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idea: {
        title: '',
        description: '',
        topic: {
          name: '',
          id: ''
        }
      },
      anonymous: true,
      submitClicked: false,
      isNameMaxLength: false,
      isDescriptionMaxLength: false,
      titleMaxLength: 60,
      descriptionMaxLength: 500,
      topicSet: false,
      topicInvalid: false,
      titleInvalid: false,
      descriptionInvalid: false
    };

    // GET TOPIC ID FROM URL
    // qs.parse(this.props.location.search).tid
  }

  setTopic = (topic) => {
    if (topic != null) {
      this.setState({
        idea: {
          ...this.state.idea,
          topic: topic
        },
        topicSet: true
      });
    } else {
      this.setState({
        idea: {
          ...this.state.idea,
          topic: {
            name: '',
            id: ''
          }
        },
        topicSet: false
      })
    }
    

  }

  handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    const length = event.target.value.length;
    let isMaxLength;

    switch (name) {
      case "title":
        isMaxLength = length >= this.state.titleMaxLength

        this.setState({
          isNameMaxLength: length >= this.state.titleMaxLength
        });
        break;
      case "description":
        isMaxLength = length >= this.state.descriptionMaxLength

        this.setState({
          isDescriptionMaxLength: length >= this.state.descriptionMaxLength
        });

        value = event.target.value.split("\n\n\n").join("\n\n&nbsp;\n\n");
        break;
    }

    if (!isMaxLength) {
      this.setState({
        idea: {
          ...this.state.idea,
          [name]: value
        }
      });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if (!this.state.idea.title.replace(/\s/g, '').length) {
      this.setState({ titleInvalid: true });
      return;
    }

    if (!this.state.idea.description.replace(/\s/g, '').length) {
      this.setState({ descriptionInvalid: true });
      return;
    }

    // if (!this.state.topicSet) {
    //   this.setState({ topicInvalid: true });
    //   return;
    // }

    this.setState({ submitClicked: true });

    const idea = {
      title: this.state.idea.title,
      description: this.state.idea.description,
      likes: 0,
      anonymous: this.state.anonymous,
      randomId: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER) + 1,
      flagged: true,
      deleted: false,
      likers: [],
      topic: {
        id: this.state.topicSet ? this.state.idea.topic.id : "_UNCATEGORIZED"
      },
      creator: {
        uid: this.props.uid,
        displayName: this.props.user.displayName,
        photoURL: this.props.user.photoURL,
        email: this.props.user.email
      },
      timestamp: moment().toDate(),
      implemented: false
    }

    const db = firebase.firestore();

    const ideasRef = db.collection("ideas");

    analytics.logEvent("add_idea", {
      anonymous: idea.anonymous,
      topicId: idea.topic.id,
      topicName: this.state.idea.topic.name,
      uid: this.props.uid
    });


    ideasRef.add(idea)
      .then((docRef) => {
        window.location.href = "/idea/" + docRef.id;
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
  }

  render() {
    return (
      <div>
        <Navbar />

        <div className="max-w-lg md:max-w-3xl mx-auto px-6 md:px-0">
          <form onSubmit={this.handleSubmit} className="-my-3 py-3">
            <div className="mt-6">
              <div className="mb-2">
                <div className="justify-end flex">
                  <a className="text-right mb-2 text-indigo-500 underline" href="/topic/new">Create a topic?</a>
                </div>
                <p className="text-4xl font-medium text-black">Post a New Idea</p>
                <p className="text-gray-500 text-xl leading-tight">Share your new idea with the community and see the feedback!</p>
              </div>
            </div>

            <div className="my-6">
              <div className="flex justify-between">
                <p className="text-gray-800 text-lg font-semibold">Title</p>
                <div className={"uppercase font-bold text-sm" + (this.state.isNameMaxLength ? " text-red-800" : " text-gray-400")}>
                  {this.state.titleMaxLength} char limit
              </div>
              </div>

              <input
                onChange={this.handleChange}
                value={this.state.idea.title}
                className={'appearance-none border border-gray-100 w-full py-2 px-3 text-gray-700 focus:outline-none focus:bg-white focus:border-blue-500 ' + (this.state.titleInvalid ? "border-red-300" : "bg-gray-200")}

                name="title"
                required
                maxLength={this.state.titleMaxLength}
              />
            </div>

            <div className="my-3">
              <div className="flex justify-between">
                <p className="text-gray-800 text-lg font-semibold">Description</p>
                <div className={"uppercase font-bold text-sm" + (this.state.isDescriptionMaxLength ? " text-red-800" : " text-gray-400")}>
                  {commaNumber(this.state.descriptionMaxLength)} char limit
              </div>
              </div>
              {/* <p className="text-gray-500">
                You can use <a href="https://www.markdownguide.org/cheat-sheet/" target="_blank" className="text-indigo-600 underline">markdown formatting</a> in your idea!<br />
                Basic formatting:<code>&emsp;&emsp;<b>**bold**</b>&emsp;&emsp;<i>*italics*</i>&emsp;&emsp;&gt; quote&emsp;&emsp;`code`&emsp;&emsp;# Header 1&emsp;&emsp;## Header 2</code><br />
                Press return two times for new line
              </p> */}
              <textarea
                onChange={this.handleChange}
                value={this.state.idea.description}
                className={"bg-gray-200 appearance-none border border-gray-100 w-full py-2 px-3 text-gray-700 focus:outline-none focus:bg-white focus:border-blue-500 " + (this.state.descriptionInvalid ? "border-red-300" : "bg-gray-200")}
                required
                name="description"
                rows="6"
              />
              {/* <div className="max-w-3xl shadow-2xl mx-auto p-10 mt-5">
                <p className="font-semibold text-black text-2xl leading-tight">Preview:</p>
                <ReactMarkdown source={this.state.idea.description} className="markdown preview" />
              </div> */}
            </div>

            <div className="my-3">
              <ChooseTopic urlTid={qs.parse(this.props.location.search).tid} setTopic={this.setTopic} topicSet={this.state.topicSet} topicInvalid={this.state.topicInvalid} />
            </div>

            {/* <div className="mt-6">
              <button className="flex justify-center items-center w-full block font-semibold text-black border-gray-200 hover:border-black border-transition-7 bg-white py-5 border-2 text-lg " type={!this.state.submitClicked ? "submit" : "button"}>
                {this.state.submitClicked ? "Loading..." : "Post Idea"}
                <i className="fas fa-angle-right ml-2"></i>
              </button>
            </div> */}

            <div className="max-w-3xl shadow-2xl mx-auto p-8 md:p-12 mt-10 mb-10">
              <p className="font-medium text-black text-2xl leading-tight">Ready to post?</p>
              <div className="flex items-center mt-4">
                <input
                  className="leading-tight"
                  type="checkbox"
                  checked={this.state.anonymous}
                  onClick={() => {
                    this.setState({ anonymous: !this.state.anonymous });
                  }}
                />
                <span className="text-md ml-2">Keep me anonymous.</span>
                <Tooltip
                  theme="light"
                  arrow={true}
                  // trigger="click"
                  position="right"
                  distance="30"
                  hideOnClick={false}
                  title="Your HW account will still be linked to the idea.">

                  <i class="ml-2 text-gray-500 text-transition hover:text-gray-700 cursor-pointer fas fa-question-circle"></i>
                </Tooltip>


              </div>

              <Tooltip
                theme="light"
                html="Your idea will be only available once it is approved by the Bulb team."
                distance="30"
                arrow={true}
                position="right"

              >
                <button type={!this.state.submitClicked ? "submit" : "button"} className="mt-6 text-2xl px-10 py-5 bg-white text-black border-2 border-gray-200 hover:border-black font-semibold border-transition-7">
                  {this.state.submitClicked ? "Loading..." : "Post Idea"}
                  <i className="fas fa-angle-right ml-3"></i>

                </button>
              </Tooltip>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default NewTopic;
