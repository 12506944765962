import React, { Component } from 'react';
import firebase from "./firebase";
import Navbar from './Navbar';
import { Tooltip } from "react-tippy"
import IdeaList from "./IdeaList"

class ReviewIdeas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      admin: false,
      topics: null,
      loading: false
    };

    this.db = firebase.firestore();

    this.checkAdmin();
  }

  checkAdmin = () => {
    const db = firebase.firestore();
    db.collection("global").doc("admins").get().then((doc) => {
      if (doc.exists) {
        if (doc.data().admins.includes(this.props.uid)) {
          this.setState({ admin: true });
        } else {
          alert("You are not an admin");
          window.location.href = "/";
        }
      }
    });
  }

  render() {

    return (
      <div>
        {(!this.state.admin || this.state.loading) ?
          <div className="absolute top-0 bottom-0 right-0 left-0 flex justify-center items-center h-screen w-screen">
            <div className="loader"></div>
          </div>
          :
          <div>
            <Navbar />

            <div className="py-20 bg-gray-100">
              <div className="max-w-3xl mx-auto px-4 md:px-0">

                <p className="text-4xl text-gray-900 font-semibold">
                  Review Ideas
                </p>

                <p class="text-gray-600 text-xl">Approve and reject ideas for the public here.</p>
              </div>
            </div>

            <div class="max-w-3xl px-4 md:px-0 mx-auto">

              <div className="mt-10">
                <IdeaList type="admin" uid={this.props.uid} />
              </div>
            </div>

          </div>
        }
      </div>
    );
  }
}

export default ReviewIdeas;
