import React, { Component } from 'react';
import firebase, { analytics } from "./firebase";
import { Tooltip } from "react-tippy";

import ReactLoading from 'react-loading';
import Navbar from './Navbar';
const moment = require('moment');

class NewTopic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      topic: {
        name: '',
        description: ''
      },
      submitClicked: false,
      isNameMaxLength: false,
      isDescriptionMaxLength: false,
      nameMaxLength: 60,
      descriptionMaxLength: 500,
    };
  }


  createKeywords = (name) => {
    const arrName = [];
    let curName = '';
    name.toLowerCase().split('').forEach((letter) => {
      curName += letter;
      arrName.push(curName);
    });
    return arrName;
  }

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const length = event.target.value.length;
    let isMaxLength;

    switch (name) {
      case "name":
        isMaxLength = length >= this.state.nameMaxLength

        this.setState({
          isNameMaxLength: length >= this.state.nameMaxLength
        });
        break;
      case "description":
        isMaxLength = length >= this.state.descriptionMaxLength

        this.setState({
          isDescriptionMaxLength: length >= this.state.descriptionMaxLength
        });
        break;
    }

    if (!isMaxLength) {
      this.setState({
        topic: {
          ...this.state.topic,
          [name]: value
        }
      });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if (!this.state.topic.name.replace(/\s/g, '').length || !this.state.topic.description.replace(/\s/g, '').length) {
      alert("Inputs must be filled");
      return;
    }

    this.setState({ submitClicked: true });

    const topic = {
      name: this.state.topic.name,
      description: this.state.topic.description,
      searchTerms: this.createKeywords(this.state.topic.name),
      reviewed: false,
      approved: false,
      randomId: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER) + 1,
      ideasSubmitted: 0,
      creator: {
        uid: this.props.uid,
        displayName: this.props.user.displayName,
        photoURL: this.props.user.photoURL,
        email: this.props.user.email
      },
      timestamp: moment().toDate()
    }

    analytics.logEvent("topic_create", {
      uid: this.props.uid
    });

    const db = firebase.firestore();

    const topicsRef = db.collection("topics");
    topicsRef.add(topic)
      .then((docRef) => {
        window.location.href = "/"
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
  }

  render() {
    return (
      <div className="">
        <Navbar />
        <div className="max-w-lg md:max-w-3xl px-4 md:px-0 mx-auto">
          <form onSubmit={this.handleSubmit} className="py-3 -my-3">
            <div className="mt-6">
              <div className="mb-2">
                <div className="justify-end flex">
                  <a className="text-right mb-2 text-indigo-500 underline" href="/idea/new">Post an idea?</a>
                </div>
                <p className="text-4xl font-medium text-black">Create New Topic</p>
                <p className="text-gray-500 text-xl leading-tight">Once approved, share your new topic with the community and see the engagement!<br /> Please note that your HW account is linked with your topic.</p>
              </div>

              <div className="flex justify-between mb-1 items-center mt-6">
                <p className="text-gray-800 text-lg font-semibold">Name</p>
                <div className={"uppercase font-bold text-sm" + (this.state.isNameMaxLength ? " text-red-800" : " text-gray-400")}>
                  {this.state.nameMaxLength} char limit
                </div>
              </div>
              <input
                onChange={this.handleChange}
                value={this.state.topic.name}
                className='focus:outline-none p-2 bg-gray-200 w-full focus:bg-white border border-gray-100 focus:border-blue-500'
                name="name"
                required
                maxLength={this.state.nameMaxLength}
              />
            </div>

            <div className="mt-5">
              <div className="flex justify-between mb-1 items-center">
                <p className="text-gray-800 text-lg font-semibold">Description</p>
                <div className={"uppercase font-bold text-sm" + (this.state.isDescriptionMaxLength ? " text-red-800" : " text-gray-400")}>
                  {this.state.descriptionMaxLength} char limit
                </div>
              </div>

              <textarea
                onChange={this.handleChange}
                value={this.state.topic.description}
                className="focus:outline-none p-2 bg-gray-200 w-full focus:bg-white border border-gray-100 focus:border-blue-500"
                name="description"
                required
                rows="6"
              />
            </div>

            <Tooltip
              theme="light"
              html="Your topic will only be available once it is approved by the Bulb team."
              distance="30"
              arrow={true}
              position="right"
            >
              <button type={!this.state.submitClicked ? "submit" : "button"} className="mt-6 text-xl px-10 py-5 bg-white text-black border-2 border-gray-200 hover:border-black font-semibold border-transition-7">
                {this.state.submitClicked ? "Loading..." : "Submit Topic"}
                <i className="fas fa-angle-right ml-3"></i>
              </button>
            </Tooltip>

          </form>
        </div>
      </div>
    );
  }
}

export default NewTopic;
