import React, { Component } from 'react';
import firebase from "firebase";
import LandingPage from './LandingPage.js';
import IdeaList from './IdeaList';
import Navbar from './Navbar';
import moment from "moment";
import { getPopularTopics } from "../helpers";
import Divider from './Divider.js';
import Topic from "./Topic";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsletterData: null,
      loading: true,
      admin: false,
      suggestedTopics: [],
    };
    this.db = firebase.firestore();

    this.fetchFeaturedNewsletter();

    if (this.props.authenticated) {
      this.checkAdmin();
    }

    this.getSuggestedTopics();

  }

  getSuggestedTopics = async () => {
    const suggestedTopics = await getPopularTopics(3);
    this.setState({ suggestedTopics });
  }

  checkAdmin = () => {
    const db = firebase.firestore();
    db.collection("global").doc("admins").get().then((doc) => {
      if (doc.exists) {
        if (doc.data().admins.includes(this.props.uid)) {
          this.setState({ admin: true });
        }
      }
    });
  }

  fetchFeaturedNewsletter = () => {
    this.db.collection("newsletters").where("deleted", "==", false).where("featured", "==", true).orderBy("timestamp", "desc").limit(1).get().then((querySnapshot) => {
      const doc = querySnapshot.docs[0];
      let newsletter = doc.data();

      newsletter.id = doc.id;

      this.setState({
        newsletterData: newsletter,
        loading: false
      })

    });
  }

  render() {
    if (this.props.authenticated) {
      return (
        <div>
          {this.state.loading ?
            <div className="absolute top-0 bottom-0 right-0 left-0 flex justify-center items-center h-screen w-screen">
              <div className="loader"></div>
            </div>
            :
            <div>
              <Navbar />

              <div className="max-w-3xl mx-auto px-4 md:px-0 mt-5">
                {
                  this.state.admin ?
                    (
                      <a href="/admin">
                        <div className="bg-transition mb-6 py-4 px-4 w-full bg-gray-800 hover:bg-gray-900 text-white shadow-md font-medium rounded">
                          <i className="mr-1 fas fa-user-shield"></i>  Visit the admin dashboard
                      </div>
                      </a>
                    ) : null
                }
                <div>
                  <div>
                    <p className="font-semibold text-black text-2xl title-font leading-tight mb-2">Student Council's Newsletter</p>
                    <p className="text-gray-500">Student Council releases monthly newsletters on updates, projects, and more. We are excited to be sharing this with you frequently written by our very own student council.</p>
                  </div>
                  <div className="mt-6">
                    <a href={this.state.newsletterData.id == null ? "" : "/newsletter/" + this.state.newsletterData.id}>
                      <div className="w-full flex bg-white border border-gray-300 hover:border-gray-600 border-transition-7 shadow-lg cursor-pointer p-5 justify-between items-center">
                        <div className="truncate">
                          <p className="font-semibold text-indigo-500 text-sm uppercase tracking-wide">{this.state.newsletterData == null ? "" : moment(this.state.newsletterData.timestamp.toDate()).format("MMM Do, YYYY")}</p>
                          <p className="font-semibold text-gray-700 text-2xl capitalize">{this.state.newsletterData == null ? "Waiting for the paper boy..." : this.state.newsletterData.title}</p>
                          <p className="text-gray-500 text-md leading-tight truncate">{this.state.newsletterData == null ? "In today's forecast: smiles and happiness all day!" : this.state.newsletterData.description}</p>
                        </div>
                        <div className="pr-1 pl-3">
                          <i className="fas fa-angle-right"></i>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="flex justify-end mt-5">
                    <a href="/newsletter" className="hover:underline text-indigo-800 font-semibold">Previous Newsletters<i className="ml-2 fas fa-arrow-right"></i></a>
                  </div>
                </div>

                {/* <Divider className="my-12" />

                <div className="">
                  <p className="uppercase text-center text-gray-600 my-5">Suggested</p>
                  {
                    this.state.suggestedTopics.map((topic) => {
                      return (
                        <Topic topic={topic} />
                      )
                    })
                  }
                </div>

                <Divider className="mt-12" /> */}
              </div>

              <div className='pt-12'>
                <div className="max-w-3xl mx-auto">
                  <div className="px-4 md:px-0">
                    {/* <div class="pb-5 flex justify-center">
                      <a class="bg-indigo-600 hover:bg-indigo-500 bg-transition text-white text-lg font-semibold py-3 px-20 shadow-lg rounded-full" href="/idea/new"><i class="fas fa-plus mr-1"></i> New Idea</a>
                    </div> */}

                    <IdeaList type="home" uid={this.props.uid} />
                  </div>
                </div>

                {/* <div className="mr-5 mb-5 lg:hidden bottom-0 right-0 fixed">
                  <button className="bg-white border-2 shadow-lg border-gray-700 rounded-full w-12 h-12 mx-auto items-center shadow" onClick={() => { window.location.href = "/idea/new" }}>
                    <i className="fas fa-plus text-lg text-gray-700"></i>
                  </button>
                </div> */}
              </div>
            </div>
          }
        </div>
      );
    } else {
      return (
        <LandingPage login={this.props.login} />

      )
    }
  }
}

export default HomePage;
