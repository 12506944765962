import React, { Component } from 'react';
import IdeaList from './IdeaList';
import Navbar from './Navbar';

class MyProfilePage extends Component {
  render() {
    return (
      <div className=''>
        <Navbar />

        <div className="max-w-3xl mx-auto pt-8 md:px-0">
          <a href="/user/feedback" class="flex justify-end text-indigo-400 hover:text-indigo-500 underline mb-4">Report a bug / submit feedback</a>

          <div className="p-6 md:p-10 bg-gray-100 border mb-2 md:mb-4">
            <div className="">
              <p className="text-gray-500 tracking-wider uppercase text-sm font-medium">User Settings</p>
              <p className="text-3xl font-medium">Settings</p>
            </div>
            <div className="mt-4 -mx-1">
              <button className="bg-gray-800 hover:bg-black bg-transition px-4 py-2 font-semibold text-white mx-1" onClick={this.props.logout}>
                Sign Out
              </button>
              <button className="bg-red-200 px-4 py-2 font-semibold text-white mx-1 cursor-not-allowed">
                Delete Account
              </button>
            </div>
          </div>

          <div className="p-6 md:p-10 bg-gray-100 border mt-10">
            <div className="">
              <p className="text-gray-500 tracking-wider uppercase text-sm font-medium">User Ideas</p>
              <p className="text-3xl font-medium">My Ideas</p>
            </div>
            <div className="mt-3 overflow-y-scroll h-64 max-h-full min-h-0 p-3 border bg-white">
              <IdeaList type="user" uid={this.props.uid} creatorUid={this.props.uid} />
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default MyProfilePage;
