import React from "react";
import firebase from "firebase";
import moment from "moment";
import ReactMarkdown from "react-markdown";
import Navbar from "./Navbar";

export default class Newsletter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: null };
    this.id = this.props.match.params.id;
    this.db = firebase.firestore();
    this.fetchData();
  }

  fetchData = () => {
    this.db.collection("newsletters").doc(this.id).get().then((doc) => {
      if (doc.exists && !doc.data().deleted) {
        this.setState({ data: doc.data() });
      } else {
        window.location.href = "/error/404"
      }
    }, (e) => {
      console.error(e);
    });
  }

  render() {

    if (this.state.data == null) {
      return (
        <div className="absolute top-0 bottom-0 right-0 left-0 flex justify-center items-center h-screen w-screen">
          <div className="loader"></div>
        </div>
      );
    }

    return (
      <div>
        <Navbar />
        <div className="pb-32">
          <div class="bg-gray-100 py-10">
            <div className="max-w-3xl mx-auto px-4 md:px-0 ">
              <div class="text-right">
                <p class="text-gray-800 font-medium italic">{moment(this.state.data.timestamp.toDate()).format("MMM Do, YYYY")}</p>
                <p class="text-gray-800 font-medium italic">Written by <span class="font-semibold">{this.state.data.author}</span></p>
              </div>
              <p className="text-4xl font-semibold leading-tight mt-6">
                {this.state.data.title}
              </p>
              <p className="text-gray-600 text-2xl leading-relaxed mt-2 font-light">{this.state.data.description}</p>

              {/* <hr class="hr-style mt-10" /> */}


            </div>
          </div>

          <div className="max-w-3xl mx-auto px-4 md:px-0 ">

            <div className="max-w-3xl mx-auto px-4 md:px-0 text-gray-900">
              <ReactMarkdown source={this.state.data.body} className="mt-8 markdown" />
            </div>
          </div>
        </div>




      </div >
    );

  }
}