import React, { Component } from 'react'
import firebase, { analytics } from "./firebase"

import PropTypes from 'prop-types';
import ReactMarkdown from "react-markdown";
import $ from 'jquery';
import { Tooltip } from 'react-tippy';
import { toast, cssTransition } from 'react-toastify';
import moment from "moment";

const Zoom = cssTransition({
  enter: 'zoomIn',
  exit: 'zoomOut',
  duration: 500,
});


// moment.locale('en', {
//   relativeTime: {
//     future: 'in %s',
//     past: '%s ago',
//     s: 'seconds',
//     ss: '%ss',
//     m: 'a minute',
//     mm: '%dm',
//     h: 'an hour',
//     hh: '%dh',
//     d: 'a day',
//     dd: '%dd',
//     M: 'a month',
//     MM: '%dM',
//     y: 'a year',
//     yy: '%dY'
//   }
// });

const commaNumber = require('comma-number');

/**
 * Displays an Idea
 * @component
 */
class Idea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      liked: this.props.idea.liked,
      likes: this.props.idea.likes,
      loading: false,
      collapsed: true,
      descriptionHeight: 1
    }

  }


  handleViewClick = () => {
    window.open("/idea/" + this.props.idea.id, "_blank");
    analytics.logEvent("idea_popout", {
      id: this.props.idea.id,
      likes: this.props.idea.likes,
      topicId: this.props.idea.topic.id,
      page: window.location.pathname,
      discovery: this.props.discovery,
      creator: this.props.idea.creator.uid,
      uid: this.props.uid
    });
  }

  handleShareClick = () => {
    var getUrl = window.location;
    var text = getUrl.protocol + "//" + getUrl.host + "/idea/" + this.props.idea.id;
    analytics.logEvent("idea_share", {
      id: this.props.idea.id,
      likes: this.props.idea.likes,
      topicId: this.props.idea.topic.id,
      page: window.location.pathname,
      discovery: this.props.discovery,
      creator: this.props.idea.creator.uid,
      uid: this.props.uid
    });
    this.copyText(text);
  }

  copyText = (text) => {
    const el = document.createElement('textarea');
    el.readOnly = true;
    el.value = text;
    document.body.appendChild(el);

    var input = el;
    var isiOSDevice = navigator.userAgent.match(/ipad|iphone/i);

    if (isiOSDevice) {

      var editable = input.contentEditable;
      var readOnly = input.readOnly;

      input.contentEditable = true;
      input.readOnly = true;

      var range = document.createRange();
      range.selectNodeContents(input);

      var selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      input.setSelectionRange(0, 999999);
      input.contentEditable = editable;
      input.readOnly = readOnly;

    } else {
      input.select();
    }

    document.execCommand('copy');
    document.body.removeChild(el);

    toast('Copied link to clipboard!', {
      position: "top-right",
      autoClose: 2000,
      transition: Zoom,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
  }

  handleDeleteClick = () => {
    this.setState({ loading: true });

    const db = firebase.firestore()
    const ideaRef = db.collection("ideas").doc(this.props.idea.id);

    $("#" + this.props.idea.id).remove();

    analytics.logEvent("idea_delete", {
      id: this.props.idea.id,
      likes: this.props.idea.likes,
      topicId: this.props.idea.topic.id,
      page: window.location.pathname,
      discovery: this.props.discovery,
      creator: this.props.idea.creator.uid,
      uid: this.props.uid
    });

    ideaRef.update({
      deleted: true,
      deletedBy: {
        uid: this.props.uid,
        reason: "self"
      }
    }).then(() => {
      // fake delay for validation

      setTimeout(() => {
        toast('Successfully deleted idea.', {
          position: "top-right",
          autoClose: 2000,
          transition: Zoom,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        $("#" + this.props.idea.id).remove();
      }, 1000)
    }, e => {
      console.error(e);
    });
  }

  handleAdminDeleteClick = (e) => {
    e.preventDefault();

    const reason = e.target.reason.value;
    const db = firebase.firestore();
    const ideaRef = db.collection("ideas").doc(this.props.idea.id);

    this.setState({ loading: true });

    analytics.logEvent("admin_delete_idea", {
      id: this.props.idea.id,
      admin: this.props.uid,
      reason: reason,
      creator: this.props.idea.creator.uid
    });

    ideaRef.update({
      deleted: true,
      deletedBy: {
        uid: this.props.uid,
        reason: reason
      }
    }).then(() => {
      let cloudfn = firebase.functions().httpsCallable('sendEmailMessage');
      cloudfn({
        to: this.props.idea.creator.email,
        idea: this.props.idea,
        reason: reason,
        topic: this.props.idea.topic.name
      }).then(() => {
        toast('Successfully deleted idea. Thank you!', {
          position: "top-right",
          autoClose: 2000,
          transition: Zoom,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        $("#" + this.props.idea.id).remove();
      }, (e) => {
        console.error(e);
      });
    }, e => {
      console.error(e);
    });
  }

  handleUnflagClick = () => {
    const db = firebase.firestore()
    const ideaRef = db.collection("ideas").doc(this.props.idea.id);
    this.setState({ loading: true });

    analytics.logEvent("admin_approve_idea", {
      id: this.props.idea.id,
      admin: this.props.uid,
      creator: this.props.idea.creator.uid
    });

    ideaRef.update({
      flagged: false
    }).then(() => {
      let cloudfn = firebase.functions().httpsCallable('sendEmailMessage');
      cloudfn({
        to: this.props.idea.creator.email,
        idea: this.props.idea,
        reason: "accepted",
        topic: this.props.idea.topic.name
      }).then(() => {
        toast('Successfully approved idea. Thank you!', {
          position: "top-right",
          autoClose: 2000,
          transition: Zoom,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        $("#" + this.props.idea.id).remove();
      }, (e) => {
        console.error(e);
      });
    });
  }

  handleFlagClick = () => {

    if(!this.props.admin){
      toast('Idea flagged! Thanks for making Bulb a better place.', {
        position: "top-right",
        autoClose: 2000,
        transition: Zoom,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }

    let flagIdea = firebase.functions().httpsCallable("flagIdea");
    this.setState({ loading: true });
    analytics.logEvent("idea_flag", {
      id: this.props.idea.id,
      likes: this.props.idea.likes,
      topicId: this.props.idea.topic.id,
      page: window.location.pathname,
      discovery: this.props.discovery,
      creator: this.props.idea.creator.uid,
      uid: this.props.uid
    });
    flagIdea({
      idea: this.props.idea.id
    }).then(() => {
      toast('Idea flagged! Thanks for making Bulb a better place.', {
        position: "top-right",
        autoClose: 2000,
        transition: Zoom,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      this.setState({ loading: false });
    }, (e) => {
      console.error(e);
    });
  }

  handleLikeClick = (event) => {
    // const db = firebase.firestore()
    // const uid = this.props.uid

    this.setState({
      liked: this.state.liked == true ? false : true,
      likes: this.state.liked == false ? this.state.likes + 1 : this.state.likes - 1,
      loading: true
    })
    analytics.logEvent("idea_like", {
      id: this.props.idea.id,
      likes: this.props.idea.likes,
      topicId: this.props.idea.topic.id,
      page: window.location.pathname,
      discovery: this.props.discovery,
      creator: this.props.idea.creator.uid,
      uid: this.props.uid
    });
    let likeIdea = firebase.functions().httpsCallable("likeIdea");
    likeIdea({
      ideaId: this.props.idea.id
    }).then(() => {
      this.setState({loading: false});
    });
  }

  componentDidMount() {
    const height = this.descriptionElem.clientHeight;
    this.setState({ descriptionHeight: height });
  }

  render() {
    const idea = this.props.idea;
    const createdByMe = this.props.uid == idea.creator.uid;
    const singleIdeaPage = (this.props.type == "single");
    const showTopicBtn = this.props.showTopicBtn;

    if (this.state.loading) {
      return (
        <div className={"h-48 px-5 pb-5 pt-3 bg-gray-100 border relative " + (this.props.discovery ? "border-indigo-500" : "border-gray-200")} id={idea.id}>
          <div className="absolute top-0 bottom-0 right-0 left-0 flex justify-center items-center">
            <div className="loader"></div>
          </div>
        </div>
      );
    }
    return (
      <a>
        <div className={"px-5 pb-5 pt-3 bg-gray-100 border " + (this.props.discovery ? "border-indigo-500" : "border-gray-200")} id={idea.id}>
          <div className="flex justify-between items-start">


          </div>

          <div className="my-3">
            <div className="flex">
              <div className="flex-1 overflow-x-hidden">
                {
                  this.props.discovery ? (
                    <p className="font-semibold text-indigo-500 text-sm uppercase tracking-wide mb-3">For You</p>
                  )
                    : null
                }
                <p className="text-2xl font-medium text-gray-900 leading-tight">
                  <a href={"/idea/" + this.props.idea.id} target="_blank">{idea.title}</a>
                </p>

                <p className={"text-md text-gray-600 mt-1 overflow-y-hidden " + (singleIdeaPage ? "" : "leading-tight break-words")} style={{
                  maxHeight: this.state.collapsed ? "75px" : "none"
                }} ref={ (elem) => { this.descriptionElem = elem } }>
                  {idea.description}
                </p>
                {
                  this.state.collapsed && this.state.descriptionHeight >= 75 ? (
                    <div className="width-full text-center text-gray-600 mb-1" onClick={() => {
                      this.setState({collapsed: false});
                    }}>
                      <Tooltip
                        position="bottom"
                        theme="light"
                        size="small"
                        interactive
                        html={(
                          <p>Read more</p>
                        )}>
                          <i className="fas fa-chevron-down" />
                      </Tooltip>
                      </div>
                  ) : null
                }
              </div>

              <div className="justify-end">
                <button className="font-bold btn-like text-gray-900 text-xl leading-none" onClick={this.handleLikeClick}>
                  <span className="">{commaNumber(this.state.likes)}</span>
                  <i className={"ml-1 fa-heart fa" + (this.state.liked ? "s" : "r")}></i>
                </button>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center">
            <div className="flex items-center -mx-2">
              {
                ("implemented" in idea) && idea.implemented === true ?
                (
                  <span className="text-xs mx-1 px-2 py-px rounded uppercase font-bold bg-white bg-indigo-600 hover:bg-indigo-800 text-gray-200 bg-transition" href={"/topic/" + idea.topic.id}>
                    Implemented!
                  </span>
                ) : null
              }
              {!showTopicBtn || idea.topic.id == "_UNCATEGORIZED" ? "" :
                <a className="text-xs mx-1 px-2 py-px rounded uppercase font-bold bg-white text-indigo-600 hover:bg-gray-200 bg-transition" href={"/topic/" + idea.topic.id}>
                  {idea.topic.name}
                </a>
              }

              <p className="text-xs mr-2 ml-2 py-px rounded title font-bold bg-gray-100 text-gray-600 ">
                {moment(idea.timestamp).fromNow()}
              </p>

              <div className="text-xs mx-1 py-px rounded uppercase font-bold bg-gray-100 text-gray-600">
                {singleIdeaPage ? "" :
                  <button className="mr-2" data-tippy-content="Open" onClick={this.handleViewClick}>
                    <i className="fas fa-external-link-alt"></i>
                  </button>
                }
                <button className="mr-2" onClick={this.handleShareClick}>
                  <i className="fas fa-share"></i>
                </button>
                <Tooltip
                  position="bottom"
                  theme="light"
                  size="small"
                  interactive
                  html={(
                    <div className="px-3 py-2">
                      <button className="text-md font-semibold text-gray-600 hover:text-gray-800 block py-1" onClick={this.handleFlagClick}>
                        <i className="fas fa-flag mr-2"></i>
                        Flag Idea
                </button>

                      {!createdByMe ? "" :
                        <button className="text-md font-semibold text-gray-600 hover:text-gray-800 block py-1" onClick={this.handleDeleteClick}>
                          <i className="fas fa-trash mr-2"></i>
                          Delete Idea
                    </button>
                      }
                    </div>
                  )}>
                  <button className="">
                    <i className="fas fa-ellipsis-h"></i>
                  </button>
                </Tooltip>
              </div>
            </div>
            <div className="">
              {
                !this.props.admin && idea.anonymous ?
                  <p className="text-gray-500 leading-none text-md font-semibold mb-2">Anonymous {!createdByMe ? "" : "(My idea)"}</p>
                  :
                  <div className="flex items-center">
                    <img src={idea.creator.photoURL} className="w-8 h-8 rounded-full border-2 border-gray-200 mr-2" />
                    <div>
                      <p className="text-gray-500 leading-none text-md font-semibold">{idea.creator.displayName}</p>
                    </div>
                  </div>
              }
              {/* {!createdByMe ? "" :
              <div className="mt-3">
                <p className="tracking-wide text-gray-600 text-sm font-medium tracking-wide text-right">Created by me</p>
              </div>
            } */}
            </div>
          </div>


          {!this.props.admin ? "" :
            <div className="flex mt-4 justify-center -mx-2">
              <button className="mx-2 text-2m bg-gray-100 text-green-500 font-bold rounded-full hover:bg-gray-200 py-2 px-6 inline-flex items-center" onClick={this.handleUnflagClick}>
                <i className="fas fa-check"></i>
              </button>

              <Tooltip
                position="bottom"
                arrow="true"
                // hideDelay="1000"
                theme="light"
                // size="small"
                trigger="click"
                interactive
                html={(
                  <form onSubmit={this.handleAdminDeleteClick} className="px-3 py-2">
                    <input required name="reason" class="w-full rounded outline-none px-3 py-2 border border-gray-300 focus:border-gray-600 border-transition-7 bg-gray-100 focus:bg-white" placeholder="Reason (spam, offensive, etc.)" />
                    <button class="text-white mt-2 w-full px-3 py-2 bg-red-500 hover:bg-red-700 font-semibold bg-transition rounded" type="submit">Reject Idea</button>

                  </form>
                )}>
                <button className="mx-2 text-2m bg-gray-100 text-red-600 font-bold rounded-full hover:bg-gray-200 py-2 px-6 inline-flex items-center">
                  <i className="fas fa-trash"></i>
                </button>
              </Tooltip>

            </div>
          }
        </div>
      </a>
    )
  }
}

Idea.propTypes = {
  db: PropTypes.instanceOf(firebase.firestore.Firestore).isRequired,
  uid: PropTypes.string.isRequired,
  /**
   * "single" if the Idea is displayed alone. Null if it is displayed in a list.
   */
  type: PropTypes.oneOf("single", null),
  showTopicBtn: PropTypes.bool,
  /**
   * If true, it styled as "for you."
   */
  discovery: PropTypes.bool
};

export default Idea;
