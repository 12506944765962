import React, { Component } from 'react';
import Navbar from './Navbar';
import {analytics} from "./firebase";

class ErrorPage extends Component {
  constructor(props){
    super(props);
    analytics.logEvent("error", {
      errorType: this.props.match.params.type
    });
  }
  render() {
    const errorType = this.props.match.params.type;
    let header, body;

    if(errorType == "admin") {
      header = "You are not an admin";
      body = "Your account was not associated with an authorized admin account."
    } else if (errorType == "404") {
      header = "404. Page not found."
      body = "Our servers weren't able to find that page."
    } else if (errorType == "login") {
      header = "You aren't logged in."
      body = "Our website requires you to be logged in.";
    } else if (errorType == "hw") {
      header = "Your account is not affiliated with Harvard-Westlake."
      body = "Our website requires you to have a Harvard-Westlake Account. Please login with your email ending in @hwemail.com or @hw.com.";
    }

    return (
      <div className=''>
        <Navbar />

        <div className="max-w-3xl mx-auto pt-6 px-4 md:px-0">
          <div className="shadow-2xl p-8">
            <div className="flex justify-start">
              <a href="/" className="tracking-wide uppercase text-sm mb-2 text-indigo-500 hover:text-indigo-600 font-semibold">
                <i className="fas fa-angle-left mr-1"></i>
                Return
              </a>
            </div>
            <p className="text-3xl font-semibold leading-none mt-1">
              {header}
            </p>
            <p className="text-lg text-gray-600 mt-3">
              {body}
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default ErrorPage;